<template>
  <v-snackbar
    :value="open"
    :color="success ? 'var(--green-new)' : 'var(--red-new)'"
    top
    timeout="5000"
    width="500px"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn small color="white" text v-bind="attrs" @click="$emit('close')">
        Fermer
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'AppSnackbar',
    props: {
      message: {
        type: String,
        required: true,
      },
      success: {
        type: Boolean,
        required: true,
      },
      open: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
