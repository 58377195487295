<template>
  <div class="mr-10">
    <v-menu
      v-model="isCalendarOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateSelection"
          :height="25"
          :style="{ fontSize: '0.8em' }"
          :disabled="disabled"
          label="Date de livraison"
          prepend-icon="mdi-calendar"
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="dateSelection"
        no-title
        locale="fr-FR"
        @input="
          () => {
            isCalendarOpen = false;
            $emit('updateDateSelection', dateSelection);
          }
        "
        @blur="$emit('updateDateSelection', dateSelection)"
      />
    </v-menu>
  </div>
</template>

<script>
  import { Moment } from 'moment-timezone';

  export default {
    name: 'AppDatePicker',
    components: {},
    props: {
      date: {
        type: Moment,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isCalendarOpen: false,
        dateSelection: this.date,
      };
    },
  };
</script>
