import {
  ConcurrencyOptions,
  HttpClient,
} from '@Collectif-Energie/collective-lib';

import config from '../../config/config';

import {
  DayAheadPricesImportParameters,
  PriceForwardCurveImportParameters,
} from './stock-tracker.interface';

export class StockTrackerClient extends HttpClient {
  constructor() {
    const headers = { 'x-api-key': config.stockTracker.apiKey };
    const options: ConcurrencyOptions = {
      maxConcurrency: 30,
      runsAllowedInDuration: 30,
      duration: 1,
    };

    super(options, `${config.stockTracker.baseUrl}/api/v1`, headers);
  }

  public importPriceForwardCurve = async (
    parameters: PriceForwardCurveImportParameters,
    file: Blob
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.post('/price-forward-curves/import', formData, {
      params: parameters,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data.result;
  };

  public importDayaheadPrices = async (
    parameters: DayAheadPricesImportParameters,
    file: Blob
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.post('/dayahead/import', formData, {
      params: parameters,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data.result;
  };
}

export default new StockTrackerClient();
