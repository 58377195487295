<template>
  <div class="pa-8 price-forward-curve-tabs">
    <AppSnackbar
      :message="snackbarMessage"
      :success="isSnackbarSuccess"
      :open="isSnackbarOpen"
      @close="isSnackbarOpen = false"
    />

    <v-card class="pa-6 price-forward-curve-tabs__card">
      <v-card-title>
        <span class="price-forward-curve-tabs__card__title">
          Importer une courbe de prix
        </span>
      </v-card-title>

      <v-card-text>
        <AppDatePicker
          class="price-forward-curve-tabs__card__input-date"
          :date="importDate"
          :disabled="importProgress"
          @updateDateSelection="updateImportDate"
        />

        <v-select
          v-model="priceType"
          class="price-forward-curve-tabs__card__input-select"
          dense
          hide-details
          height="20"
          placeholder="Sélectionner un type de prix"
          :disabled="importProgress"
          :items="priceTypeSelectItems"
          :error="!priceType && $v.$dirty"
        />

        <v-select
          v-model="commodity"
          class="price-forward-curve-tabs__card__input-select"
          dense
          hide-details
          height="20"
          placeholder="Sélectionner un type de fourniture"
          :disabled="importProgress"
          :items="commoditySelectItems"
          :error="!commodity && $v.$dirty"
        />

        <v-file-input
          class="price-forward-curve-tabs__card__input-file"
          accept=".csv"
          truncate-length="35"
          placeholder="Sélectionner un fichier"
          @change="selectFile"
          :disabled="importProgress"
          :error="!file && $v.$dirty"
        />

        <v-card-actions>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <v-spacer />
          <v-btn small color="primary" text @click="resetForm">
            Réinitialiser le formulaire
          </v-btn>
          <v-btn
            small
            color="primary"
            :loading="importProgress"
            @click.prevent="importPrices"
          >
            Importer
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { required } from 'vuelidate/lib/validators';
  import { mapState } from 'vuex';

  import stockTrackerService from '../../services/stock-tracker/stock-tracker.service';
  import AppDatePicker from '../app/AppDatePicker.vue';
  import AppSnackbar from '../app/AppSnackbar.vue';
  import { Commodity } from '@Collectif-Energie/collective-lib';
  import {
    StockPriceType,
    Zone,
  } from '../../services/stock-tracker/stock-tracker.interface';
  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'PriceForwardCurveTabs',
    components: { AppDatePicker, AppSnackbar },
    props: {
      validation: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        importDate: moment().format('YYYY-MM-DD'),
        file: null,
        priceType: null,
        commodity: null,
        priceTypeSelectItems: Object.values(StockPriceType),
        commoditySelectItems: ['Electricité', 'Gaz'],
        snackbarMessage: '',
        isSnackbarOpen: false,
        isSnackbarSuccess: true,
        importProgress: false,
        errorMessage: null,
      };
    },
    validations: {
      importDate: { required },
      commodity: { required },
      file: { required },
    },
    computed: {
      ...mapState('auth', ['user']),
    },
    methods: {
      updateImportDate(date) {
        this.importDate = date;
      },
      selectFile(file) {
        this.file = file ? file : null;
      },
      resetForm() {
        this.importDate = moment().format('YYYY-MM-DD');
        this.commodity = 'Electricité';
        this.priceType = StockPriceType.PriceForwardCurve;
        this.file = null;
      },
      displaySnackbar(isSuccess, message) {
        this.snackbarMessage = message;
        this.isSnackbarSuccess = isSuccess;
        this.isSnackbarOpen = true;
      },
      buildImportParameters() {
        return {
          commodity:
            this.commodity === 'Electricité' ? Commodity.Power : Commodity.Gas,
          actor: this.user.displayName,
          date: new Date(this.importDate),
          origin: 'EEX',
          priceType: this.priceType,
          zone: Zone.Fr,
        };
      },
      async importPrices() {
        this.errorMessage = null;

        this.$v.$touch();
        this.validation();

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(MATOMO_EVENTS.importStockPrices);

          try {
            this.importProgress = true;

            const parameters = this.buildImportParameters();
            await stockTrackerService.importPrices(parameters, this.file);

            this.displaySnackbar(true, 'Données importées avec succès');
          } catch (err) {
            let message;
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            else message = err.message;

            this.errorMessage = message;
          } finally {
            this.importProgress = false;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .price-forward-curve-tabs {
    &__card {
      color: var(--grey);

      &__title {
        font-size: 1.25em;
        padding-bottom: 4rem;
        color: var(--primary-new);
      }

      &__input-date,
      &__input-select {
        width: 20rem;
        margin-bottom: 2rem;
      }

      &__input-file {
        display: flex;
        width: 50rem;
        margin-bottom: 2rem;
      }
    }
  }
</style>
