import { Commodity } from '@Collectif-Energie/collective-lib';

export enum StockPriceType {
  PriceForwardCurve = 'PFC',
  DayAheadPrice = 'SPOT',
}

export enum Zone {
  Fr = 'FR',
}

export interface StockTrackerImportParameters {
  actor: string;
  commodity: Commodity;
  date: Date;
  origin: string;
  priceType: StockPriceType;
  zone: Zone;
}

export interface CommonPriceImportParameters {
  actor: string;
  commodity: Commodity;
  origin: string;
  zone: Zone;
}

export interface PriceForwardCurveImportParameters
  extends CommonPriceImportParameters {
  deliveryDate: Date;
}

export interface DayAheadPricesImportParameters
  extends CommonPriceImportParameters {
  diffusionDatetime: Date;
}
