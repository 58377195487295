import stockTrackerClient from './stock-tracker.client';
import {
  DayAheadPricesImportParameters,
  PriceForwardCurveImportParameters,
  StockPriceType,
  StockTrackerImportParameters,
} from './stock-tracker.interface';

export class StockTrackerService {
  public async importPrices(
    parameters: StockTrackerImportParameters,
    file: Blob
  ): Promise<void> {
    if (parameters.priceType === StockPriceType.PriceForwardCurve)
      await this.importPriceForwardCurve(parameters, file);

    if (parameters.priceType === StockPriceType.DayAheadPrice)
      await this.importDayaheadPrices(parameters, file);
  }

  private async importPriceForwardCurve(
    parameters: StockTrackerImportParameters,
    file: Blob
  ): Promise<void> {
    const priceForwardCurveParameters: PriceForwardCurveImportParameters = {
      actor: parameters.actor,
      commodity: parameters.commodity,
      origin: parameters.origin,
      zone: parameters.zone,
      deliveryDate: parameters.date,
    };

    await stockTrackerClient.importPriceForwardCurve(
      priceForwardCurveParameters,
      file
    );
  }

  private async importDayaheadPrices(
    parameters: StockTrackerImportParameters,
    file: Blob
  ): Promise<void> {
    const dayAheadPricesImportParameters: DayAheadPricesImportParameters = {
      actor: parameters.actor,
      commodity: parameters.commodity,
      origin: parameters.origin,
      zone: parameters.zone,
      diffusionDatetime: parameters.date,
    };

    await stockTrackerClient.importDayaheadPrices(
      dayAheadPricesImportParameters,
      file
    );
  }
}

export default new StockTrackerService();
